<template>
  <div
    id="side-widget"
    :class="{ active: openWidget }"
  >
    <div
      class="always-visible"
      @click="openWidget = !openWidget"
    >
      <img src="@/assets/livraison-icon.png">
      <img
        src="@/assets/livraison-arrow.png"
        class="arrow"
      >
    </div>

    <!-- tablet and desktop !-->
    <div class="dark-side-widget d-none d-md-flex">
      <div class="dark-side">
        <div>
          <img
            src="@/assets/npa-icon.png"
            class="big-icon"
          >
          <span>{{ $t('content.side.home.title') }}</span>
          <form @submit.prevent.stop="searchByZip">
            <input
              ref="zipField"
              v-model="zip"
              type="text"
              :placeholder="$t('content.side.home.zip')"
              name="npa"
            >
            <button type="submit" :disabled="!valid">
              <img src="@/assets/search-icon.png">
            </button>
          </form>
        </div>

        <div>
          <img
            src="@/assets/hub-icon.png"
            class="big-icon"
          >
          <span>{{ $t('content.side.hub') }}</span>
          <router-link :to="{ name: 'hubs' }">
            <img
              src="@/assets/map-icon.svg"
              class="small-icon"
            />
          </router-link>
        </div>
        <div>
          <img
            src="@/assets/better-experience-icon.png"
            class="big-icon"
          >
          <template v-if="!connected">
            <span>{{ $t('content.side.login.title') }}<br>{{ $t('content.side.login.login') }}</span>
            <a href="#" @click="login">
              <img
                src="@/assets/user-icon.svg"
                class="small-icon"
              >
            </a>
          </template>
          <span v-else>{{ $t('content.side.login.logged') }}</span>
        </div>
      </div>
      <div class="dark-side plus-info">
        <span>{{ $t('content.side.delivery') }} <router-link
          :to="$siteLink($route, 'livraison')"
          target="blank"
        >{{ $t('content.side.deliveryHere') }}</router-link></span>
      </div>
    </div>
  </div>
</template>

<script>
import SideWidgetMixin from '@/mixins/SideWidgetMixin'
export default {
  name: 'SideWidget',
  mixins: [SideWidgetMixin],
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openWidget: false
  }),
  computed: {
    connected() {
      return this.$store.getters['auth/connected']
    }
  },
  mounted() {
    this.openWidget = this.opened
  },
  methods: {
    login() {
      this.$store.commit('modal/show', {
        component: 'LoginPopup',
        params: {}
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#side-widget {
  display: flex;
  background-color: var(--yellow-color);
  position: fixed;
  right: 0;
  box-shadow: -3px 2px 11px #00000059;
  z-index: 99;
  height: 105px;
  width: 50px;
  top: 20%;
  min-height: 420px;
}

#side-widget.active {
  height:fit-content !important;
  width: 90%;

  @include media-breakpoint-up(lg) {
    width:max-content;
  }
}

#side-widget.active .always-visible .arrow {
  transform: rotate(180deg);
}
#side-widget:not(.active) .dark-side {
  width: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

#side-widget:not(.active) .dark-side-mobile {
  width: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

img {
  max-width: 100%;
}
.always-visible {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
  cursor: pointer;
  min-width: 50px;
}
.always-visible img {
  max-height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.always-visible .arrow {
  aspect-ratio: 29 / 47;
}
.dark-side-widget {
  display: flex;
  flex-direction: column;
}
.dark-side {
  display: flex;
  padding: 40px 20px;
  max-height: 400px;

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
}
.dark-side > div {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  border-right: 2px dashed white;

  @include media-breakpoint-up(lg) {
    padding: 0 45px;
  }
}
.dark-side > div:first-child {
  padding-left: 0;
}
.dark-side > div:last-child {
  border: unset;
  padding-right: 0;
}
.dark-side .big-icon {
  max-width: 80px;
  max-height: 80px;
}
.dark-side > div:first-child .big-icon {
  max-width: 115px;
  max-height: 115px;
}
.dark-side .small-icon {
  width: 60px;
  cursor: pointer;
  background: #ffffff;
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: -1px 3px 11px #00000069;
}
.dark-side span {
  font-weight: 700;
  font-size: 18px;
  color: white;
  margin: 15px 0;
}
.dark-side form {
  display: flex;
  margin-top: 10px;
}
.dark-side form input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  box-shadow: -1px 3px 11px #00000069;
  padding: 0 10px;
  width: 120px;

  &:focus-visible {
    outline: none;
  }
}
.dark-side form button {
  background-color: var(--yellow-color);
  border: none;
  box-shadow: 3px 3px 6px #00000069;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  width: 50px;
}
.dark-side.plus-info {
  padding:0px 70px !important;
  justify-content: center;

  a {
    color: white;
    text-decoration: underline;
  }
}
#hub-map {
  position: fixed;
  left: 5%;
  right: 5%;
  background-color: var(--yellow-color);
  top: 15%;
  overflow-y: scroll;
  padding: 30px 70px;
  box-shadow: -1px 3px 11px #00000069;
  max-width: 800px;
  bottom: 5%;

  @include media-breakpoint-up(lg) {
    left: 25%;
    right: 25%;
  }
}
#hub-map:not(.active) {
  display: none;
}
</style>
