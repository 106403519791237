export default {
  data: () => ({
    loading: false,
    zip: null
  }),

  computed: {
    valid() {
      return this.zip && !this.loading
    },
    switch() {
      return this.$store.getters['sidew/switch']
    },
    urlParams() {
      return this.$store.getters['sidew/data']
    }
  },
  watch: {
    switch: 'focus',
    openWidget(v) {
      if (!v) {
        this.$store.commit('sidew/reset')
      }
    }
  },

  methods: {
    searchByZip() {
      if (!this.valid) {
        return
      }
      this.loading = true
      return this.$store.dispatch('hub/searchByZip', { zip: this.zip })
        .then(hubs => {
          if (!hubs.length) {
            // no hub found, go to hubs selection page
            return this.$router.push({ name: 'hubs', query: { ...this.urlParams, notfound: true } })
          }

          const partnerId = hubs[0].partners[0]

          if (!this.urlParams.categoryId) {
            // no direct filter on category, go to partner homepage
            return this.$router.push({ name: 'partner', params: { partnerId } })
          }
          // go directly on shops that match this category in partner
          return this.$router.push({ name: 'shops', params: { partnerId }, query: this.urlParams })
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    focus() {
      this.openWidget = true
      if (this.$refs.zipField) {
        this.$refs.zipField.focus()
      }
    }
  }
}
