<template>
  <div
    id="side-widget"
    :class="{ active: openWidget }"
  >
    <div
      class="always-visible col-2"
      @click="openWidget = !openWidget"
    >
      <img
        src="@/assets/livraison-icon.png"
        class="livraison"
      >
      <img
        src="@/assets/livraison-arrow.png"
        class="arrow"
      >
    </div>

    <div class="slider col-10">
      <agile
        :center-mode="true"
        :speed="1000"
        :dots="false"
      >
        <div class="slide">
          <img
            src="@/assets/livraison-icon-big.png"
            class="big-icon"
          >
          <span>{{ $t('content.side.home.title') }}</span>
          <form @submit.prevent.stop="searchByZip">
            <input
              v-model="zip"
              type="text"
              :placeholder="$t('content.side.home.zip')"
              name="npa"
            >
            <button type="submit" :disabled="!valid">
              <img src="@/assets/search-icon.png">
            </button>
          </form>
        </div>

        <div class="slide">
          <img
            src="@/assets/hub-icon.png"
            class="big-icon"
          >
          <span>{{ $t('content.side.hub') }}</span>
          <router-link :to="{ name: 'hubs' }">
            <img
              src="@/assets/map-icon.svg"
              class="small-icon"
            />
          </router-link>
        </div>

        <div class="slide">
          <img
            src="@/assets/better-experience-icon.png"
            class="big-icon"
          >
          <template v-if="!connected">
            <span>{{ $t('content.side.login.title') }}<br>{{ $t('content.side.login.login') }}</span>
            <a href="#" @click="login">
              <img
                src="@/assets/user-icon.svg"
                class="small-icon"
              >
            </a>
          </template>
          <span v-else>{{ $t('content.side.login.logged') }}</span>
        </div>

        <template slot="prevButton">
          <div class="prev">
            <div class="arrow" />
          </div>
        </template>
        <template slot="nextButton">
          <div class="next">
            <div class="arrow" />
          </div>
        </template>
      </agile>
      <div class="plus-info">
        {{ $t('content.side.delivery') }}
        <router-link
          :to="$siteLink($route, 'livraison')"
          target="blank"
        >
          {{ $t('content.side.deliveryHere') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SideWidgetMixin from '@/mixins/SideWidgetMixin'
import { VueAgile } from 'vue-agile'
export default {
  name: 'SideWidgetMobile',
  components: {
    agile: VueAgile
  },
  mixins: [SideWidgetMixin],
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openWidget: false
  }),
  computed: {
    connected() {
      return this.$store.getters['auth/connected']
    }
  },
  mounted() {
    this.openWidget = this.opened
  },
  methods: {
    login() {
      this.$store.commit('modal/show', {
        component: 'LoginPopup',
        params: {}
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.prev, .next {
  color: transparent !important;
  width: 30px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  cursor: pointer;
  @include media-breakpoint-up(lg) {
    width: 70px !important;
  }
}
.prev {
  background: url("~@/assets/slider-prev-white.svg") no-repeat;
}
.next {
  background: url("~@/assets/slider-next-white.svg") no-repeat;
}
#side-widget:not(.active) {
  transform: translateX(calc(100vw - 95px));
  height: 100px;

  .prev, .next {
    display: none;
  }
}

#side-widget.active {
  transform: translateX(1%);
  .livraison {
    display: none;
  }
  .always-visible {
    justify-content: flex-end;

    .arrow {
      transform: rotate(180deg);
    }
  }
  .slider {
    width: 100%;
  }
}

#side-widget {
  background-color: var(--yellow-color);
  display: flex;
  position: fixed;
  top: 25%;
  right: 0;
  z-index: 99;
  width: 90%;

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 55px 40px 10px;

    .big-icon {
      max-width: 100px;
      max-height: 70px;
    }

    .small-icon {
      max-width: 50px;
      max-height: 50px;
      cursor: pointer;
      background: #ffffff;
      padding: 5px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: -1px 3px 11px #00000069;
    }

    span {
      color: white;
      font-weight: 700;
      font-size: 16px;
      margin: 15px 0;
    }
    form {
      display: flex;

      input {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;
        box-shadow: -1px 3px 11px #00000069;
        padding: 0 10px;
        height: 50px;
        width: 150px;

        &:focus-visible {
          outline: none;
        }
      }
      button {
        background-color: var(--yellow-color);
        border: none;
        box-shadow: 3px 3px 6px #00000069;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;
         img {
           width:25px;
         }
      }
    }
  }

  .plus-info {
    max-width: 250px;
    color: white;
    font-weight: 700;
    padding-bottom: 20px;

    a {
      color: white;
      text-decoration: underline;
    }
  }
}

.always-visible {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
  cursor: pointer;
}
.always-visible img {
  max-height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.always-visible .arrow {
  aspect-ratio: 29 / 47;
}

#hub-map {
  position: fixed;
  left: 0;
  right: 5%;
  background-color: var(--yellow-color);
  top: -70px;
  bottom: -100px;
  overflow-y: scroll;
  padding: 30px 10px;
  box-shadow: -1px 3px 11px #00000069;

  @include media-breakpoint-up(lg) {
    left: 25%;
    right: 25%;
  }
}
#hub-map:not(.active) {
  display: none;
}

</style>
